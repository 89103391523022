.about-us {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 200px;
}

.plus {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 125px;
}

.rectangle-u1 {
    -webkit-backdrop-filter: blur(10px) brightness(100%);
    backdrop-filter: blur(10px) brightness(100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
    border-radius: 20px;
    position: absolute;
    width: 95%;
    height: 80%;
}


.rectangle-plus1n1 {
    -webkit-backdrop-filter: blur(10px) brightness(100%);
    backdrop-filter: blur(10px) brightness(100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
    border-radius: 20px;
    position: absolute;
    width: 95%;
    height: 80%;
}

.ellipse-u1 {
    background-color: #ed65c7;
    border-radius: 40px;
    height: 80px;
    width: 80px;
    position: absolute;
    top: 0;
    left: 0;
}

.ellipse-u2 {
    background-color: #ed65c7;
    border-radius: 40px;
    height: 80px;
    width: 80px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.image-fixed {
    width: 100px;
    height: 100px;
}

.text-wrapper-u1 {
    margin-inline: 100px;
    margin-top: 15px;
    color: #ffc6ef;
    font-weight: 400;
    line-height: 65px;
    position: absolute;
    text-align: center;
    font-size: 60px;
}

.text-wrapper-plus1n1 {
    margin-inline: 30px;
    position: absolute;
    color: #ffc6ef;
    font-weight: 400;
    text-align: center;
    font-size: 35px;
    line-height: 35px;
}

.span-u1 {
    color: #FFCEF1;
}

.text-wrapper-u2 {
    font-weight: 600;
    color: #ffa0e4;
}

@media (max-width: 1440px) {
    .text-wrapper-u1 {
        margin-inline: 40px;
        font-size: 50px;
        line-height: 50px;
    }

    .text-wrapper-plus1n1 {
        font-size: 35px;
        line-height: 35px;
        font-weight: 400;
    }
}

@media (max-width: 768px) {
    .about-us {
        height: 170px;
    }

    .plus {
        height: 110px;
    }

    .ellipse-u1 {
        width: 40px;
        height: 40px;
    }

    .ellipse-u2 {
        width: 40px;
        height: 40px;
    }

    .rectangle-u1 {
        height: 80%;
    }

    .rectangle-plus1n1 {
        height: 80%;
    }

    .text-wrapper-u1 {
        margin-inline: 30px;
        font-size: 40px;
        line-height: 40px;
    }

    .text-wrapper-plus1n1 {
        font-weight: 400;
        font-size: 28px;
        line-height: 28px;
    }
}


@media (max-width: 480px) {
    .image-fixed {
        width: 80px;
        height: 80px;
    }

    .plus {
        height: 105px;
    }

    .about-us {
        height: 140px;
    }

    .ellipse-u1 {
        width: 30px;
        height: 30px;
    }

    .ellipse-u2 {
        width: 30px;
        height: 30px;
    }

    .rectangle-u1 {
        height: 75%;
    }

    .rectangle-plus1n1 {
        height: 75%;
    }

    .text-wrapper-u1 {
        margin-inline: 25px;
        font-size: 25px;
        line-height: 25px;
    }

    .text-wrapper-plus1n1 {
        font-weight: 400;
        font-size: 19px;
        line-height: 19px;
    }
}

