@font-face {
    font-family: 'Tilda Sans';
    src: url('./TildaSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Tilda Sans';
    src: url('./TildaSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Tilda Sans';
    src: url('./TildaSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Tilda Sans';
    src: url('./TildaSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Tilda Sans';
    src: url('./TildaSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Tilda Sans';
    src: url('./TildaSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Tilda Sans';
    src: url('./TildaSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
