* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.logo {
    cursor: pointer;
    padding: 5px;
    max-width: 80px;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #262626 !important;
    color: #fff !important;
}

a:hover {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

button {
    border: 0;
    background-color: transparent;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

@media (min-width: 1700px) {
    main .container {
        max-width: 100%;
        padding: 0 150px;
    }
}

p.success {
    color: green;
}

p.danger {
    color: red;
}

