.preview-text {
    font-size: 30px;
    font-weight: 400;
}

.text {
    color: #FFF0FB;
}

.text-pink {
    font-weight: 600;
    color: #ffa0e4;
}


@media (max-width: 768px) {
    .preview-text {
        font-size: 20px;
    }
}

@media (max-width: 576px) {
    .preview-text {
        font-size: 16px;
    }
}


